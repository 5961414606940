import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// InputField component remains unchanged
function InputField({ label, id, name, placeholder, value, onChange }) {
     return (
          <div className="form-group">
               <label htmlFor={id}>{label}</label>
               <input
                    type="text"
                    className="form-control"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
               />
          </div>
     );
}

// Define the style for the loading spinner
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red; // You can change the color to match your theme
`;

function App() {
     const [shortenedUrl, setShortenedUrl] = useState('');
     const [originalUrl, setOriginalUrl] = useState('');
     const [showShortenedUrl, setShowShortenedUrl] = useState(false);
     const [isLoading, setIsLoading] = useState(false);
     const [customShortenedUrl, setCustomShortenedUrl] = useState('');
     const inputRef = useRef(null);

     useEffect(() => {
          const endpoint = window.location.pathname.substring(1);
          if (endpoint) {
               window.location.href = `https://shortrl-backend-poojanghetiyas-projects.vercel.app/${endpoint}`;
          }
     }, []);

     const handleFormSubmit = async (event) => {
          event.preventDefault();
          try {
               setIsLoading(true);
               const data = prepareFormData();
               const responseData = await shortenUrl(data);
               handleSuccess(responseData);
          } catch (error) {
               handleError(error);
          } finally {
               setIsLoading(false);
          }
     };

     const prepareFormData = () => {
          return {
               originalUrl,
               customShortenedUrl,
          };
     };

     const shortenUrl = async (data) => {
          const response = await fetch('https://shortrl-backend-poojanghetiyas-projects.vercel.app/shorten', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify(data),
          });

          if (!response.ok) {
               throw new Error('Failed to shorten URL');
          }

          return response.json();
     };

     const handleSuccess = (responseData) => {
          const baseURL = window.location.origin;
          setShortenedUrl(baseURL + '/' + responseData.shortUrl);
          setShowShortenedUrl(true);
     };

     const handleError = (error) => {
          console.error('Error:', error);
          // Handle the error here (e.g., show an error message to the user)
     };

     const handleCopyClick = () => {
          if (inputRef.current) {
               inputRef.current.select();
               document.execCommand('copy');
               alert('Copied (•‿•)');
          }
     };

     return (
          <>
               <div className="container mt-5">
                    <div className="row justify-content-center">
                         <div className="col-md-8">
                              <div className="card animated-card">
                                   <div className="card-header bg-primary text-white">
                                        <h3 className="mb-0">URL Shortener</h3>
                                   </div>
                                   <div className="card-body">
                                        <form onSubmit={handleFormSubmit}>
                                             <InputField
                                                  label="Enter your long URL:"
                                                  id="originalUrl"
                                                  name="originalUrl"
                                                  placeholder="https://www.example.com"
                                                  value={originalUrl}
                                                  onChange={(e) => setOriginalUrl(e.target.value)}
                                             />
                                             <InputField
                                                  label="Custom Shortened URL (optional):"
                                                  id="customShortenedUrl"
                                                  name="customShortenedUrl"
                                                  placeholder="Custom URL (e.g., my-custom-url)"
                                                  value={customShortenedUrl}
                                                  onChange={(e) => setCustomShortenedUrl(e.target.value)}
                                             />
                                             <button type="submit" className="btn btn-primary btn-block">
                                                  {isLoading ? (
                                                       <ClipLoader color={'#ffffff'} css={override} size={15} />
                                                  ) : (
                                                       'Shorten URL'
                                                  )}
                                             </button>
                                        </form>

                                        {showShortenedUrl && (
                                             <div className="mt-3">
                                                  <label htmlFor="shortenedUrl">Shortened URL:</label>
                                                  <div className="input-group">
                                                       <input
                                                            type="text"
                                                            ref={inputRef}
                                                            className="form-control"
                                                            id="shortenedUrl"
                                                            name="shortenedUrl"
                                                            readOnly
                                                            value={shortenedUrl}
                                                       />
                                                       <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleCopyClick}
                                                       >
                                                            Copy URL
                                                       </button>
                                                  </div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default App;